<template>
    <span class="input-group">
        <input
            v-model="hour"
            @input="input"
            :required="required"
            :disabled="disabled"
            placeholder="HH"
            type="number"
            step="1"
            min="0"
            max="99"
            class="form-control text-center"
        >
        <span class="input-group-text px-1" :class="{'bg-white': !disabled}">:</span>
        <input
            v-model="minute"
            @input="input"
            @blur="blur"
            :required="required"
            :disabled="disabled"
            placeholder="MM"
            type="number"
            step="5"
            class="form-control text-center"
        >
    </span>
</template>

<script>
export default {
    name: 'FormInputMinutes',
    props: {
        modelValue: {
            type: Number
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            hour: this.modelValue === null ? null : Math.floor(this.modelValue / 60),
            minute: this.modelValue === null ? null : ("0" + (this.modelValue % 60)).slice(-2),
        }
    },
    emits: [
        'update:modelValue',
        'validlyChange',
    ],
    methods: {
        input() {
            // 両方空ならnull
            if (
                (this.hour === "" || this.hour === null || isNaN(this.hour))
                && (this.minute === "" || this.minute === null || isNaN(this.minute))
            ) {
                this.$emit("update:modelValue", null);
                return;
            }

            // どっちか入ってるなら埋める
            if (this.hour === "" || this.hour === null || isNaN(this.hour)) {
                this.hour = 0;
            }
            if (this.minute === "" || this.minute === null || isNaN(this.minute)) {
                this.minute = 0;
            }

            this.minute = parseInt(this.minute);
            this.hour = parseInt(this.hour);

            if (this.minute >= 60) {
                this.hour += Math.floor(this.minute / 60);
                this.minute = this.minute % 60;
            }
            if (this.minute < 0) {
                while (this.minute < 0) {
                    if (this.hour > 0) {
                        this.hour -= 1;
                        this.minute += 60;
                    } else {
                        this.minute = 0;
                    }
                }
            }

            if (this.hour < 0) {
                this.hour = 0;
            }
            this.$emit("update:modelValue", parseInt(this.hour) * 60 + parseInt(this.minute));
        },
        blur() {
            this.minute = ("0" + this.minute).slice(-2);
        }
    },
    watch: {
        modelValue(after) {
            this.hour = after === null ? null : Math.floor(after / 60);
            this.minute = after === null ? null : after % 60;
            this.minute = ("0" + this.minute).slice(-2);
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance:textfield;
}
</style>
